import React from "react"
import { Box } from "rebass"
import { InternalLink } from "@fragments"
const faqs = require("@constants/faqs");

const OtherErrors = ({questionToSkip}) => {
  //listing all remaining faqs below is leading to indexing problems so making it 15 items instead
  const faqsMinusCurrent = faqs.filter(e => e.question !== questionToSkip);
  const faqsShuffled = faqsMinusCurrent.sort(() => 0.5 - Math.random());
  const random15FAQs = faqsShuffled.slice(0, 15);
  return (
    <Box mt={[32, 62]}>
      <h2>Other Common DynamoDB FAQ (with Answers)</h2>
      <ul>
        {random15FAQs
          .map(faq => (
            <li style={{ marginBottom: 0 }} key={faq.slug} className="list-item-with-spacing">
              <InternalLink to={`/dynamodb-faq/${faq.slug}/`}>
                {faq.question}
              </InternalLink>
            </li>
          ))}
      </ul>
    </Box>
  )
}

export default OtherErrors;
