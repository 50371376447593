import React from "react"
import { Container } from "@components"
import { Box, Flex } from "rebass"
import OtherFAQs from "./OtherFAQs"
import ReactMarkdown from "react-markdown";
import "github-markdown-css/github-markdown-light.css";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {okaidia} from 'react-syntax-highlighter/dist/esm/styles/prism'

const Answer = ({ answer, questionToSkip }) => {
  return (
    <Container>
      <Box mt={[32, 62]}>
        <Flex flexDirection="column">
          <h2>Answer</h2>
          <ReactMarkdown
            children={answer}
            components={{
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={okaidia}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              }
            }}
          />
        </Flex>
        <OtherFAQs questionToSkip={questionToSkip} />
      </Box>
    </Container>
  )
}

export default Answer
