import React from "react"
import { FooterWithCTA, SEO, Layout, Hero } from "@components"
import Answer from "@components/pages/faq/SingleFAQPage/Answer.js"
import SideCTA from "@components/SideCTA"

const FAQPage = ({ pageContext: { question, slug, answer } }) => {
  return (
    <>
      <Layout>
        <SEO
          title={`[Answered] ${question}`}
          description={`This page answers the following question: ${question}`}
          canonical={`https://dynobase.dev/dynamodb-faq/${slug}/`}
        />
        <Hero title={`Question: ${question}`} includeAuthorCustom/>
        <Answer answer={answer} questionToSkip={question}/>
        <FooterWithCTA />
      </Layout>
      <SideCTA />
    </>
  )
}

export default FAQPage;